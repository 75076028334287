var jquery_ready = $.fn.ready;
$.fn.ready = function() {
  var args = Array.prototype.slice.call(arguments, 0);
  // console.log('add event');
  window.addEventListener('turbo:load', function() {
    // window.TURBO_LOADED = true;
    // console.log('in tubo load');
    jquery_ready.apply($.fn, args);
  });
};

window._jQuery = $;